<template>
  <Alert class="tip">
    <p class="title">提交成功!</p>
    <p class="desc">您的账号需要审核!</p>
    <p class="desc">审核结果将发送至邮箱，请耐心等待</p>
  </Alert>
</template>

<script>
  export default { }
</script>

<style lang="less">
  .tip {
    margin: 30px;
    padding: 30px;
    line-height: 1;
    .title {
      color: #19be6b;
      font-size: 30px;
      line-height: 35px;
    }
    .desc {
      font-size: 25px;
      line-height: 35px;
    }

  }
  
</style>
